import { Col, Row, Text, Input, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect, Grid, RangeDatePicker, modal, WalletBalanceRenderer, Select, Table, showPopupMessage, Tooltip, BgIcon } from 'components';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IScreen, TCMSOrder, TCMSPipeline, TOnlineStore, TOrder, TPrintJob, TShopifyOrder, TUser } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR, shippingPrice, shippingPriceEurope, shippingPriceTheRestOfTheWorld, EUROPEAN_COUNTRIES, TAX_ONLY_RATE, DISCOUNT_RATE, SAMPLE_DISCOUNT, COLORS } from 'const';
import { ActivityIndicator, Image, useWindowDimensions } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { Feather, Ionicons, FontAwesome5, MaterialIcons, MaterialCommunityIcons, AntDesign, EvilIcons } from '@expo/vector-icons';
import RequestUtils from 'store/api-client/Request.utils';
import { useOrderStore } from 'store/Order.Store';
import ToastHelper from 'helpers/ToastHelper';
import DownloadPDFsModal from './DownloadPDFsModal';
import DispatchRoyal from './DispatchRoyal';
import { usePaymentStore } from "store/Payment.Store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { commonFuncs } from './ListOrders.funcs';
import { TimeHelper, ValHelper } from 'helpers';
import { notification, Button as AntButton, Popover, Badge } from 'antd';
import GlobalEvent from 'js-events-listener/react';
import SelectShippingService from './SelectShippingService';
import { TABS as OrderStatusArray } from './ListOrders.Tabs'
import { ColumnsType } from 'antd/es/table';
import OrderTable from 'components/elements/table/OrderTablePro';
import { downloadArtworkAndPackingSlip } from 'helpers/FileHelper';
import { getPDFDataFromPipelines } from './utils';

export const ListOrderLoading = () => {
  return (
    <Col>
      <Row height={50} stretch mt2>
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
      </Row>
      <Row height={50} stretch mt2>
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
        <ShimmerLoading round1 flex={1} m1 />
      </Row>
    </Col>
  )
}

export const ListOrderLoadMore = ({ hasNext, getData, isLoadingMore, page }: any) => {
  if (!hasNext) return null;
  return (
    <Col middle mt1>
      <TouchField
        onPress={() => {
          getData(page + 1);
        }}
        borderWidth={1}
        borderColor={COLOR.MAIN}
      >
        <Col width={200} height={40} middle>
          <Text>{isLoadingMore ? 'Loading more...' : 'Load more'}</Text>
        </Col>
      </TouchField>
    </Col>
  )
}

export const Checkbox = ({ isSelected, toggleSelect, translateY = 0, color = 'black' }) => {
  return (
    <TouchField cirle middle onPress={toggleSelect} transform={[{ translateY: translateY }]}>
      {isSelected ? (
        <Ionicons name="checkmark-circle" size={18} color={COLOR.MAIN} />
      ) : (
        <FontAwesome5 name="circle" size={18} color={color} />
      )}
    </TouchField>
  );
}

export const OrderLineItems = ({ order, supportedItemIDs }: { order: TShopifyOrder, supportedItemIDs: string }) => {
  const renderNonBreakingSpace = (quantity) => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `x&nbsp;${quantity}`
        }}
      />
    )
  }
  if (!order) return null;
  const lineItems = order.line_items?.filter(v => (supportedItemIDs || '').includes(String(v.id)));
  return (
    <Row>
      <Col flex1>
        <Text numberOfLines={1} fontSize={14} mb0><Text fontSize={14} bold>{lineItems[0]?.name}</Text> {renderNonBreakingSpace(lineItems[0]?.quantity)}</Text>
      </Col>
      {lineItems?.length > 1 && (
        <Popover
          content={(
            <Col>
              {lineItems.map((i, idx) => (
                <Text fontSize={13} key={`${i.id}${idx}`}>{i.name}</Text>
              ))}
            </Col>
          )}
          trigger="hover"
        >
          <Badge
            color={COLORS.ICON}
            count={
              <Text color={COLORS.TEXT} bold>{`+${lineItems.length - 1}`}</Text>
            }
          />
        </Popover>
      )}
      {/* {v.sku && (<Text fontSize={10}>{v.sku}</Text>)} */}
    </Row>
  );
}

export const OrderLineItemsRaw = ({ items }: { items: { name: string, quantity: number }[] }) => {
  const renderNonBreakingSpace = (quantity) => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `x&nbsp;${quantity}`
        }}
      />
    )
  }
  if (!items?.length) return null;
  return (
    <Row>
      <Col flex1>
        <Text numberOfLines={1} fontSize={14} mb0><Text fontSize={14} bold>{items[0]?.name}</Text> {renderNonBreakingSpace(items[0]?.quantity)}</Text>
      </Col>
      {items?.length > 1 && (
        <Popover
          content={(
            <Col>
              {items.map((i, idx) => (
                <Text fontSize={13} key={`${i.id}${idx}`}>{i.name}</Text>
              ))}
            </Col>
          )}
          trigger="hover"
        >
          <Badge
            color={COLORS.ICON}
            count={
              <Text color={COLORS.TEXT} bold>{`+${items.length - 1}`}</Text>
            }
          />
        </Popover>
      )}
    </Row>
  );
}

export const TableRow = ({ index, isSelected, children }) => {
  return (
    <Col backgroundColor={isSelected ? COLOR.MAIN + '25' : (index % 2 === 0 ? COLOR.GREY_LIGHT : undefined)}>
      {children}
    </Col>
  );
}

export const inProductionCheckData: {
  [orderId: string]: {
    isDownloadedArtwork: boolean,
    isOpenedPackagingSlips: boolean
  }
} = {};

export const updateInProductionCheckData = (order: TCMSOrder, data: any) => {
  const orderId = order['Order ID'];
  inProductionCheckData[orderId] = {
    ...inProductionCheckData[orderId],
    isDownloadedArtwork: true,
    ...data
  };
  if (!!inProductionCheckData[orderId]?.isOpenedPackagingSlips
    && !!inProductionCheckData[orderId]?.isDownloadedArtwork
  ) {
    modal.show(
      <ModalConfirmInProduction
        orders={[order]}
      />
    )
  }
}

export const ModalConfirmInProduction = ({ orders }: { orders: Array<TCMSOrder> }) => {
  const [loading, setLoading] = useState(false);
  const OrderStore = Store.useOrderStore();

  const onConfirm = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < orders.length; i++) {
        await OrderStore.dispatchToRoyalMail(orders[i]);
      }
    } catch (error) {
      notification.error({ message: error.message || JSON.stringify(error) });
    } finally {
      setLoading(false);
    }
    modal.hide();
    window.location.reload();
    // GlobalEvent.emit('REFRESH_LIST_ORDERS', true);
  }

  return (
    <Col width={320} p2 bgWhite round1 shadow>
      <Text center mb2 fontSize={18}>Do you want to change {orders.length === 1 ? 'this order' : 'these orders'} status to "In production"</Text>
      <Row>
        <Col flex1 marginRight={10}>
          <Col>
            <Button
              outline
              height={30}
              width='100%'
              text='Yes'
              isLoading={loading}
              onPress={onConfirm}
            />
          </Col>

        </Col>
        <Col flex1>
          <Col>
            <Button
              solid
              height={30}
              width='100%'
              text='No'
              onPress={() => modal.hide()}
            />
          </Col>
        </Col>
      </Row>
    </Col>
  )
}

export const DownloadArtwork = ({ order, apiSlug, onRefresh }: { order: TCMSOrder, apiSlug: string, onRefresh: any }) => {
  const { route } = useNavFunc();
  // @ts-ignore
  const showRerun = !!route?.params?.showRerun;
  const UserStore = Store.useUserStore();
  const { reRunJob, loadingReRun } = useOrderStore();
  const pipelines = order.Pipelines;
  const { pdfStages, displayValue } = useMemo(() => {
    return getPDFDataFromPipelines(pipelines);
  }, [pipelines]);
  console.log('pdfStages', pdfStages)

  const handlePressDownload = () => {
    // if (displayValue.pdfs.length === 1) {
    //   const { lineId, orderId, pdf, name } = displayValue.pdfs[0];
    //   // saveAs(pdf, `${orderId}_${lineId}_${name}.pdf`);
    //   window.open(pdf, '_blank');
    //   if (UserStore.user?.role === 'admin') {
    //     updateInProductionCheckData(order, { isDownloadedArtwork: true });
    //   }
    //   return;
    // }
    modal.show(<DownloadPDFsModal pdfs={displayValue.pdfs} order={order} />)
  };

  const handlePressReRun = async () => {
    if (!pdfStages[pdfStages.length - 1]) return;
    if (loadingReRun) return;
    const { lastJob, pipelineId } = pdfStages[pdfStages.length - 1];
    try {
      ToastHelper.showProgress(0.1);
      await reRunJob({
        jobId: lastJob.jobId,
        pipelineId,
        slug: apiSlug,
      });
      ToastHelper.showProgress(0.2);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      ToastHelper.showProgress(0.4);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      ToastHelper.showProgress(0.6);
      onRefresh?.();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      ToastHelper.showProgress(0.7);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      ToastHelper.showProgress(0.9);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      onRefresh?.();
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(error?.message);
    } finally {
      ToastHelper.showProgress(1);
    }
  }

  const handlePressReRun_Debug = async (jobId) => {
    if (!jobId) return;
    if (!pdfStages[pdfStages.length - 1]) return;
    const { pipelineId } = pdfStages[pdfStages.length - 1];
    try {
      await reRunJob({
        jobId,
        pipelineId,
        slug: apiSlug,
      });
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    }
  }

  const handlePressReRunCandleGenerate = async () => {
    if (!pdfStages[pdfStages.length - 1]) return;
    if (loadingReRun) return;
    const { lastCandleJob, pipelineId } = pdfStages[pdfStages.length - 1];
    try {
      console.log("lastCandleJob", lastCandleJob);
      ToastHelper.showProgress(0.1);
      await reRunJob({
        jobId: lastCandleJob.jobId,
        pipelineId,
        slug: apiSlug,
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
      onRefresh?.();
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(error?.message);
    } finally {
      ToastHelper.showProgress(1);
    }
  }

  if (pipelines.length === 0) return (
    <Tooltip
      title="Not started"
      trigger="mouseenter"
      position="top"
    >
      <MaterialIcons name="change-history" size={24} color="gray" />
    </Tooltip>
  );

  // console.log('displayValue', displayValue);
  if (!displayValue.hasPDFStage) return (
    <Text caption></Text>
  );

  if (displayValue.status === 'Pending') {
    if (displayValue.isStuck) return (
      <Row alignItems={"center"}>
        <Tooltip
          title="Stuck"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={() => {
            if (!displayValue.log) return;
            window.open(displayValue.log, '_blank');
          }}>
            <MaterialIcons name="warning" size={24} color="orange" />
          </TouchField>
        </Tooltip>
        <Tooltip
          title="Re-generate PDF"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={handlePressReRun}>
            <MaterialIcons name="replay" size={22} color={COLOR.MAIN} />
          </TouchField>
        </Tooltip>
      </Row>
    )
    return (
      <Tooltip
        title="In Progress..."
        trigger="mouseenter"
        position="top"
      >
        <MaterialCommunityIcons name="play-speed" size={24} color="blue" />
      </Tooltip>
    );
  }


  if (displayValue.isStuckCandle) {
    return (
      <Row alignItems={"center"}>
        <Tooltip
          title="Generating Candle Label Stuck"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={() => {
            if (!displayValue.logCandle) return;
            window.open(displayValue.logCandle, '_blank');
          }}>
            <MaterialIcons name="warning" size={24} color="orange" />
          </TouchField>
        </Tooltip>
        <Tooltip
          title="Re-generate Candle Label"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={handlePressReRunCandleGenerate}>
            <MaterialIcons name="replay" size={22} color={COLOR.MAIN} />
          </TouchField>
        </Tooltip>
      </Row>
    )
  }

  if (displayValue.status === 'Failed') {
    return (
      <Row alignItems={"center"}>
        <Tooltip
          title="Error"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={() => {
            if (!displayValue.log) return;
            window.open(displayValue.log, '_blank');
          }}>
            <MaterialIcons name="warning" size={24} color="orange" />
          </TouchField>
        </Tooltip>
        <Tooltip
          title="Re-generate PDF"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={handlePressReRun}>
            <MaterialIcons name="replay" size={22} color={COLOR.MAIN} />
          </TouchField>
        </Tooltip>
      </Row>
    )
  }

  if (!displayValue.pdfs?.length) {
    const debugPdfJob = false;
    if (!debugPdfJob) return null;
    return (
      <Row>
        <TouchField onPress={() => console.log("order", order)} mr2>
          <Text>Log</Text>
        </TouchField>
        <TouchField onPress={() => handlePressReRun_Debug("")}>
          <Text>Rerun</Text>
        </TouchField>
      </Row>
    )
  }

  return (
    <Row alignItems={"center"}>
      <Tooltip
        title="Download artworks"
        trigger="mouseenter"
        position="top"
      >
        <TouchField cirle middle onPress={handlePressDownload}>
          <Feather name="download" size={20} color="black" />
        </TouchField>
      </Tooltip>
      {showRerun && (
        <Tooltip
          title="Re-generate PDF"
          trigger="mouseenter"
          position="top"
        >
          <TouchField marginTop={-4} cirle middle onPress={handlePressReRun}>
            <MaterialIcons name="replay" size={22} color={COLOR.MAIN} />
          </TouchField>
        </Tooltip>
      )}
    </Row>
  );
}

export const DetailRow = ({ label, value, extra }: any) => {
  return (
    <Row mb0 borderBottomColor={COLOR.BORDER + '80'} borderBottomWidth={0.5}>
      <Col width={100} pv1>
        <Text bold>{label}</Text>
      </Col>
      <Row flex1>
        <Text>{value}</Text>
        {extra}
      </Row>
    </Row>
  )
}

export const ClientName = ({ order, canShowId }: { order: TCMSOrder, canShowId?: boolean }) => {
  const [showId, setShowId] = useState(false);
  const [findUser, setFindUser] = useState<TUser>(null);
  const [findShop, setFindShop] = useState<TOnlineStore>(null);
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  useEffect(() => {
    setFindUser(UserStore.users[order['Client ID']]);
  }, [UserStore.users]);
  useEffect(() => {
    setFindShop(ShopStore.shops[order['Store ID']]);
  }, [ShopStore.shops, UserStore.user]);

  const clientName = useMemo(() => {
    const storeName = findShop?.name;
    let cName = (() => {
      if (!findUser) return order['Client Name'];
      return findUser.accountName || [findUser.firstName, findUser.lastName].filter(Boolean).join(' ');
    })();
    const last4DigistId = order['Client ID']?.slice(-4);
    if (cName === 'Bottled Goose' && order['Client ID'] !== '440478626329') cName = 'Client ' + last4DigistId;
    const displayName = [cName, storeName].filter(Boolean).join('\n');
    // console.log('cName', cName, 'storeName', storeName, 'Store ID', order['Store ID'], '==> ', displayName);
    return displayName;
  }, [findUser, order, findShop])

  return (
    <Col onPress={canShowId ? () => setShowId(v => !v) : undefined}>
      <Text>{clientName}</Text>
      {showId && (
        <Text caption mt0>
          Client Id: {order['Client ID']}
          <a href={`/resellers/${order['Client ID']}/transactions`} target='_blank' style={{ marginLeft: 4 }}>
            <Ionicons name="open-sharp" size={12} color="black" />
          </a>
        </Text>
      )}
    </Col>
  );
}

export const PreviewIcon = ({ order }: { order: TCMSOrder }) => {
  const pipelines = order.Pipelines;
  const previewItems = pipelines.length === 0 ? [] : (pipelines[pipelines.length - 1].SharedData.canBeProcessedItems || []).filter(v => !!v.previewUrl);
  const canPreview = previewItems.length > 0;
  const { width, height } = useWindowDimensions();
  const IMG_SIZE = Math.min(width * 0.8, height * 0.8, 600);
  const retryGenPreview = () => {
    // use this to manual re-run job
    console.log(pipelines[pipelines.length - 1]);
    // reRunJob({
    //   jobId: "s1_1697867227360_0",
    //   pipelineId: 152,
    //   slug: 'piw',
    //   deploymentAddress: "https://services.personify.tech",
    //   force: true,
    // });
  }

  const showPreviewImages = (e) => {
    console.log('order', order);
    e.stopPropagation();
    const image = previewItems.length === 1 ? (
      <Image
        style={{
          width: IMG_SIZE,
          height: IMG_SIZE,
        }}
        resizeMode='contain'
        source={{ uri: previewItems[0].previewUrl }}
      />
    ) : (
      <Row flexWrap='wrap'>
        {previewItems.map((val) => {
          return (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode='contain'
              source={{ uri: val.previewUrl }}
            />
          )
        })}
      </Row>
    );
    modal.show(image);
  };

  let iconColor = '';
  let imgQualityStatus = '';
  if (canPreview) {
    if (previewItems.some(i => /Sexual|Nudity/.test(JSON.stringify(i?.imageQuality?.labels)))) {
      iconColor = 'red';
      imgQualityStatus = "Artwork contains explicit content - please review";
    } else if (previewItems.some(i => i.imageQuality?.sharpness < 0.8)) {
      iconColor = 'orange';
      imgQualityStatus = "Double-check your artwork to ensure you're satisfied with it";
    } else if (previewItems.some(i => i.imageQuality?.sharpness)) {
      iconColor = COLOR.GREEN;
      imgQualityStatus = "The quality of artwork is good";
    }
  }

  return (
    <Popover
      content={(
        <Text>{imgQualityStatus}</Text>
      )}
      trigger="hover"
    >
      <AntButton
        type="text" style={{ borderRadius: 3 }}
        onClick={!canPreview ? undefined : showPreviewImages}
      >
        <BgIcon name="media" size={14} color={iconColor} />
      </AntButton>
    </Popover>
  );
}

export const TrackingID = ({ order }: { order: TCMSOrder }) => {
  const { navigation } = useNavFunc();
  const [{ loading }, setUI] = useUIState({ loading: true });
  const [displayText, setDisplayText] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingNumberShareData, setTrackingNumberShareData] = useState('');

  useEffect(() => {
    const last = order.Pipelines[order.Pipelines.length - 1];
    if (!last) return;
    const shareData = last.SharedData;
    if (!shareData) return;
    const trackingNumber = shareData.trackingNumber;
    if (!trackingNumber) return;
    setTrackingNumberShareData(trackingNumber);
  }, [order]);

  useEffect(() => {
    const last = order.Pipelines[order.Pipelines.length - 1];
    if (!last?.SharedData?.trackingNumber) {
      setDisplayText('Awaiting dispatch');
    } else if (!!last?.SharedData?.trackingNumber) {
      setDisplayText(last?.SharedData?.trackingNumber);
      setTrackingNumber(last?.SharedData?.trackingNumber);
    } else {
      setDisplayText('Awaiting postage');
    }
    setUI({ loading: false });
  }, [order]);
  return (
    <Col>
      <Col middle borderThin height={30} round0 overflow={'hidden'}
        // onPress={() => navigation.navigate(SCREEN.OrderDetail, { orderId: order['Order ID'] })}
        onPress={() => {
          if (trackingNumber || trackingNumberShareData) {
            navigation.navigate(SCREEN.OrderDetail, { orderId: order['Order ID'] });
            return;
          }
          modal.show(
            <DispatchRoyal
              order={order}
              navigation={navigation}
            />
          )
        }}
      >
        {loading && (
          <Text caption center>Loading...</Text>
        )}
        {trackingNumberShareData ? (
          <Text fontSize={8} center>{trackingNumberShareData}</Text>
        ) : (
          <>
            {displayText && (
              <Text fontSize={8} center>{displayText}</Text>
            )}
          </>
        )}

      </Col>
    </Col>
  )
}

export const DateDispatched = ({ order }: { order: TCMSOrder }) => {
  const { navigation } = useNavFunc();
  const [{ loading }, setUI] = useUIState({ loading: true });
  const [displayText, setDisplayText] = useState('');
  const [logUrl, setLogUrl] = useState('');

  useEffect(() => {
    const last = order.Pipelines[order.Pipelines.length - 1];
    if (last?.SharedData?.dispatchedDate) {
      setDisplayText(`* ${last?.SharedData?.dispatchedDate}`);
      setUI({ loading: false });
      return;
    }
    if (!last?.SharedData?.trackingNumber) {
      const job = (() => {
        const jobs = last?.Jobs || {};
        const find = Object.keys(jobs).map(jobId => jobs[jobId]).find(job => job.Title === 'Create Order in RoyalMail');
        return find;
      })();
      if (!!job && job.Status === 'Failed') {
        setDisplayText('Failed');
        setLogUrl(job.Log);
      } else {
        setDisplayText('Awaiting dispatch');
      }
    } else if (!!last?.SharedData?.shippedOn) {
      setDisplayText(
        moment(last?.SharedData?.shippedOn).format('DD/MM/YYYY HH:mm')
      )
    } else {
      setDisplayText('Awaiting postage');
    }
    setUI({ loading: false });
  }, [order]);
  return (
    <Col alignItems="flex-start">
      <Col overflow={'hidden'}
        onPress={() => navigation.navigate(SCREEN.OrderDetail, { orderId: order['Order ID'] })}
      >
        {loading && (
          <Text caption center>Loading...</Text>
        )}
        {displayText === 'Failed' ? (
          <Tooltip
            title="Failed to Dispatch"
            trigger="mouseenter"
            position="top"
          >
            <Col width={30} height={30} middle onPress={() => {
              window.open(logUrl, '_blank');
            }}>
              <MaterialIcons name="warning" size={24} color="orange" />
            </Col>
          </Tooltip>
        ) : displayText === 'Awaiting dispatch' ? (
          <Tooltip
            title="Awaiting dispatch"
            trigger="mouseenter"
            position="top"
          >
            <Col width={30} height={30} middle>
              {/* <MaterialCommunityIcons name="play-speed" size={24} color="blue" /> */}
              <Text caption>TBC</Text>
            </Col>
          </Tooltip>
        ) : (
          <Text marginLeft={7} caption>{displayText}</Text>
        )}
      </Col>
    </Col>
  )
}


export const OrderNo = ({ order, canShowId }: { order: TCMSOrder, canShowId?: boolean }) => {
  const { navigation } = useNavFunc();
  const [displayType, setDisplayType] = useState<'none' | 'id' | 'name'>('none');
  const toggleType = () => {
    switch (displayType) {
      case 'none': return setDisplayType('id');
      case 'id': return setDisplayType('name');
      case 'name': return setDisplayType('none');
    }
  }
  const sourceId = order?.['Order Source ID'];

  return (
    <Col
      width={90}
      // onPress={canShowId ? toggleType : undefined}
      onPress={() => navigation.navigate(SCREEN.OrderDetail, { orderId: order['Order ID'] })}
    >
      <Text numberOfLines={1} textDecorationLine={'underline'}>#{sourceId ? order['Order ID'] : order?.['Order Number']}</Text>
      {displayType === 'id' && (
        <Text mt0 caption>Id: {order['Order ID']}</Text>
      )}
      {displayType === 'name' && (
        <Text mt0 caption>Name: {order['Order Name']}</Text>
      )}
    </Col>
  )
}

export const ResellerAcceptOrdersModal = ({ orders, navigation, isReprint, onSuccess }: { orders: Array<TCMSOrder>, navigation: any, isReprint?: boolean, onSuccess?: () => void }) => {
  const OrderStore = Store.useOrderStore();
  const { height: screenHeight } = useWindowDimensions();
  const { stripePromise, getStripeConfig, refreshBalance } = usePaymentStore();
  // const [totalAmount, setTotalAmount] = useState<{ amount: number, currency: string, shouldIncludeVAT: boolean }>();

  const [invoices, setInvoices] = useState<Array<{ includeTax: boolean, orderAmountWithTax: number, discountRate: number, orderNumber?: string, shippingAddress: { country: string; } }>>([]);
  const [stripeInvoiceItems, setStripeInvoiceItems] = useState<any[]>([]);
  const [stripeInvoices, setStripeInvoices] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [shipping, setShipping] = useState("RM48");
  const [sampleToken, setSampleToken] = useState(0);

  const unpaidOrders = isReprint ? orders : orders.filter(v => !v.Pipelines[v.Pipelines.length - 1]?.SharedData.isPaid);
  console.log('unpaidOrders', unpaidOrders);

  const useSampleToken = unpaidOrders.some(i => i['Raw Data']?.is_sample_request) && sampleToken > 0;

  useEffect(() => {
    getStripeConfig();
  }, []);

  useEffect(() => {
    (async () => {
      await refreshBalance();
      if (unpaidOrders.length === 0) return;
      const res = await Api.User.getResellerSampleToken();
      setSampleToken(res.data.data || 0);
      let _sampleToken = res.data.data || 0;
      let data = await OrderStore.getChargeAmount(unpaidOrders);
      // if (data?.orderWeights && Object.keys(data.orderWeights).some(i => data.orderWeights[i] >= 2000)) {
      //   setShipping("DPD");
      // }

      // try to create invoice for invalid orders
      const invalidStripeOrders = unpaidOrders?.filter(i => (
        !data?.stripeInvoices?.some(j => j?.metadata?.orderNumber === i?.['Order Number'])
      ));
      if (invalidStripeOrders?.length) {
        for (let i = 0; i < invalidStripeOrders.length; i++) {
          let _order = { ...invalidStripeOrders[i] };
          delete _order.Pipelines;
          await Api.Order.createInvoice({
            order: _order
          })
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        data = await OrderStore.getChargeAmount(unpaidOrders);
      }

      // all selected order must have stripe invoices to active this mode
      if (data?.stripeInvoices?.length && data?.stripeInvoices?.length === data?.invoices?.length) {
        const arr = [];
        data?.stripeInvoices.map(item => {
          const orderNo = item.metadata?.orderNumber;
          const dbInvoice = data?.invoices?.find(i => i.orderNumber === orderNo);

          const shipItem = item.lines.data?.find(i => i.description?.includes("Shipping fee"));
          const taxItem = item.lines.data?.find(i => i.description?.includes("VAT "));

          const _useSampleToken = dbInvoice?.data?.isSampleRequest && _sampleToken > 0;
          let productsOnly = 0;
          if (!shipItem && !taxItem) {
            const discountedAmount = _useSampleToken ? Math.round(Number(item.total) * DISCOUNT_RATE) : item.total;
            arr.push({ orderNo, item: "Products", value: discountedAmount });
          } else {
            productsOnly = item.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
            const discountedAmount = _useSampleToken ? Math.round(productsOnly * DISCOUNT_RATE) : productsOnly;
            arr.push({ orderNo, item: "Products", value: discountedAmount });
          }
          if (shipItem) {
            arr.push({ orderNo, item: "Shipping fee", value: shipItem?.amount });
          }
          if (taxItem) {
            let discountedTax = taxItem?.amount;
            if (_useSampleToken) discountedTax = Math.round(taxItem.amount - productsOnly * SAMPLE_DISCOUNT * TAX_ONLY_RATE);
            arr.push({ orderNo, item: "VAT (20%)", value: discountedTax });
          }
          if (!shipItem) {
            const shippingFee = (() => {
              if (!dbInvoice?.shippingAddress) return shippingPrice.RM48;
              if (dbInvoice?.shippingAddress?.country === 'United Kingdom') {
                return shippingPrice["RM48"] || 0;
              }
              if (EUROPEAN_COUNTRIES.includes(dbInvoice?.shippingAddress?.country)) {
                return shippingPriceEurope["RM48"] || 0;
              }
              return shippingPriceTheRestOfTheWorld["RM48"] || 0;
            })();
            arr.push({ orderNo, item: "Shipping fee", value: shippingFee * 100 });

            if (dbInvoice?.taxes) {
              const shippingFeeTax = shippingFee * TAX_ONLY_RATE * 100;
              const totalTax = Math.round(Number(item?.total) * TAX_ONLY_RATE + shippingFeeTax)
              arr.push({ orderNo, item: "VAT (20%)", value: totalTax });
            }
          }
        });
        setStripeInvoiceItems(arr);
      }

      setStripeInvoices(data?.stripeInvoices);
      // setInvoices((data?.invoices || []).map(v => {
      //   if (data?.stripeInvoices?.some(i => i?.metadata?.orderNumber === v?.orderNumber)) return;

      //   const isSampleRequest = !!v.data?.isSampleRequest;
      //   const shouldDiscount = !isSampleRequest ? false : (_sampleToken > 0 ? true : false);
      //   const discountRate = shouldDiscount ? DISCOUNT_RATE : 1;
      //   return {
      //     orderNumber: v.orderNumber,
      //     includeTax: Boolean(v.taxes),
      //     orderAmountWithTax: v.total * discountRate,
      //     discountRate,
      //     shippingAddress: v.shippingAddress || v.customerInfo,
      //   }
      // }).filter(Boolean));
      // setTotalAmount(data);
      setLoading(false);
    })();
  }, []);

  const totalPrice = useMemo(() => {
    let totalOfStripeInvoices = 0;
    if (stripeInvoiceItems?.length) {
      totalOfStripeInvoices = stripeInvoiceItems.reduce((total, item) => {
        return total + (item?.value || 0);
      }, 0) / 100;
    };
    // const shippingPriceEach = shippingPrices[shipping];
    const productWithVAT = invoices.length === 0 ? 0 : invoices.reduce((a, b) => ({
      includeTax: a.includeTax || b.includeTax,
      orderAmountWithTax: a.orderAmountWithTax + b.orderAmountWithTax,
      discountRate: 1,
      shippingAddress: a.shippingAddress,
    })).orderAmountWithTax;
    // const shippingWithVAT = invoices.map(v => v.includeTax ? shippingPriceEach * 1.2 : shippingPriceEach).reduce((a, b) => a + b, 0);
    const shippingWithVAT = (() => {
      let shippingFee = 0;
      invoices.forEach(v => {
        const country = v.shippingAddress?.country;
        const taxRate = v.includeTax ? 1 + TAX_ONLY_RATE : 1;
        console.log('taxRate', taxRate)
        if (country === 'United Kingdom') {
          shippingFee += shippingPrice[shipping] * taxRate;
        } else if (EUROPEAN_COUNTRIES.includes(country)) {
          shippingFee += shippingPriceEurope[shipping] * taxRate;
        } else {
          shippingFee += shippingPriceTheRestOfTheWorld[shipping] * taxRate;
        }
      });
      return shippingFee;
    })();
    const totalOfOldInvoices = productWithVAT ? (productWithVAT + shippingWithVAT + 0.01) : 0;
    return totalOfStripeInvoices + totalOfOldInvoices;
  }, [invoices, orders, stripeInvoiceItems]);

  const lineItemColumns: ColumnsType<any> = [
    {
      title: 'Order No',
      key: 'orderNo',
      dataIndex: 'orderNo',
      render: (value) => `#${value}`,
      align: 'center'
    },

    {
      title: 'Product Price',
      dataIndex: 'products',
      key: 'products',
      render: (products) => "£" + (Number(products) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'Shipping',
      dataIndex: 'shippingfee',
      key: 'shippingfee',
      render: (shippingfee) => "£" + (Number(shippingfee) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'VAT 20%',
      dataIndex: 'vat',
      key: 'vat',
      render: (vat) => "£" + (Number(vat || 0) / 100).toFixed(2),
      align: 'center'
    },
  ]

  if (!stripePromise) return null;
  // console.log("stripeInvoiceItems", stripeInvoiceItems)
  const mergeItems = (items) => {
    const mergedItems = {};

    for (const item of items) {
      const orderNo = item.orderNo;
      if (!mergedItems[orderNo]) {
        mergedItems[orderNo] = { orderNo }; // Initialize object for current orderNo
      }

      const transformedItem = {
        [item.item.toLowerCase().replace(/\s+/g, '').replace(/\(.*\)/g, '')]: item.value // Lowercase, remove spaces, camelCase, remove parentheses
      };
      mergedItems[orderNo] = { ...mergedItems[orderNo], ...transformedItem }; // Add transformed item
    }

    return Object.values(mergedItems); // Convert object back to an array of merged objects
  }

  // Filter data based on specific orderNo (if provided) or merge all
  const filteredData = () => {
    return mergeItems(stripeInvoiceItems); // Merge all items if no filter provided
  };

  const onAcceptPayment = async (walletBalance) => {
    if (walletBalance < totalPrice) return;
    try {
      if (isPaying) return;
      setIsPaying(true);
      if (stripeInvoices?.length) {
        await OrderStore.chargeFromWalletByStripeInvoice(
          orders.filter(i => stripeInvoices.some(j => j?.metadata?.orderNumber === i["Order Number"])),
          stripeInvoices.map(i => i.id),
          shipping,
          useSampleToken,
        )
      } else {
        throw new Error('Payment order not found')
      }
      // if (invoices?.length) {
      //   await OrderStore.chargeFromWallet(orders.filter(i => invoices.some(j => j.orderNumber === String(i["Order Number"]))), shipping);
      // }
      if (isReprint) {
        await commonFuncs.reprint(orders[0], true);
        navigation.setParams({
          stage: 'in-production',
        });
      } else {
        if (onSuccess) {
          onSuccess();
        } else {
          navigation.reset({
            index: 0,
            routes: [
              { name: SCREEN.ListOrders, params: { stage: 'pre-production' } },
            ],
          });
        }
      }
      // navigation.setParams({ status: 'accepted' });
      // alert('You have successfully paid these orders. they are now awaiting admin approval.');
      modal.hide();
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
      // alert(err);
      console.log(err);
    } finally {
      setIsPaying(false);
    }
  }

  // To merge all orders:
  const allMergedData = filteredData();
  // console.log("allMergedData",allMergedData)
  return (
    <Elements stripe={stripePromise}>
      <Col width={450} bgWhite round1 shadow maxHeight={screenHeight - 24} borderColor={"rgb(16, 24, 64)"} borderWidth={"2px"} >
        <WalletBalanceRenderer>
          {({ balance }) => {

            const walletBalance = (balance?.balance * -1 || 0) / 100

            const renderBalance = () => {
              return (
                <Text bold>£{ValHelper.formatBalance(walletBalance)} {String(balance?.currency || "GBP").toUpperCase()}</Text>
              )
            }

            return unpaidOrders.length === 0 ? (
              <Col flex1 p2 middle>
                <Text>You have already paid these orders. Please wait for admin approval.</Text>
              </Col>
            ) : loading ? (
              <Col flex1 p2 middle>
                <ActivityIndicator color={COLOR.MAIN} size='small' />
              </Col>
            ) : (
              <Col flex1 mv2>
                <Text mh2 bold mb1 fontSize={18} textAlign={"center"}>Pay and proceed</Text>
                <Text mh2 bold mb2 fontSize={18} textAlign={"center"}>Balance: {renderBalance()}</Text>
                {/* DEFAULT TO RM48 FOR NOW */}
                {/* <SelectShippingService onChange={setShipping} value={shipping} /> */}
                {!!shipping && (
                  isReprint ?
                    <Col>
                      <Text mv0>
                        Reprint this job will take funds from your wallet. Once paid, the order cannot be amended.
                      </Text>
                      <Text mv0 center>Total: £<Text bold fontSize={20}>{ValHelper.formatMoney(totalPrice)} GBP</Text></Text>
                      <Text mv0 center bold>Do you wish to continue?</Text>
                    </Col>
                    :
                    <Col>
                      <Text mv0 mh3>
                        Accepting these jobs will take funds from your wallet. Once paid, these orders cannot be amended. Bottled Goose will review these orders before they go into production.
                      </Text>
                      {!!stripeInvoiceItems?.length && (
                        <Col mv1 >
                          <OrderTable
                            style={{
                              maxHeight: 320,
                              overflowY: "auto",
                            }}
                            data={allMergedData}
                            className="payList"
                            rowClassName="stripeInvoiceTable"
                            columns={lineItemColumns}
                            rowKey={record => `${record.id}`}
                            sticky={true}

                          />
                        </Col>
                      )}
                      <Text mv0 center>Total: <Text bold fontSize={20}>£{ValHelper.formatMoney(totalPrice)} GBP</Text></Text>
                      <Text mv0 center bold>Do you wish to continue?</Text>
                    </Col>
                )}
                <Row mh2>
                  <Col flex1 marginRight={10}>
                    <Col>
                      <Button
                        outline
                        height={30}
                        width='100%'
                        text='Cancel'
                        onPress={async () => {
                          modal.hide();
                        }}
                      />
                    </Col>
                  </Col>
                  {(walletBalance < totalPrice || walletBalance === 0) && (
                    <Col flex1 marginRight={10}>
                      <Col>
                        <Button
                          outline
                          height={30}
                          width='100%'
                          text='Add balance'
                          onPress={async () => {
                            navigation.navigate(SCREEN.Settings, { tabId: 'billing' });
                            modal.hide();
                          }}
                        />
                      </Col>
                    </Col>
                  )}
                  <Col flex1>
                    <Col>
                      <Button
                        opacity={walletBalance < totalPrice ? 0.5 : 1}
                        solid
                        isLoading={isPaying}
                        height={30}
                        width='100%'
                        text='Accept'
                        onPress={TimeHelper.throttle(() => onAcceptPayment(walletBalance))}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )
          }}
        </WalletBalanceRenderer>
      </Col>
    </Elements>

  )
}
